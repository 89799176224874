/* POSTS */

article {
	clear: both;
	margin: 0 0 35px 0;
}

blockquote {
	background: url(assets/images/icons/blockquote.png) no-repeat 0 12px;
	padding: 10px 20px 10px 50px;
	font-style: italic;
}
blockquote p { }

.post { }

.alignleft {
	float: left;
	width: auto;
	margin: 5px 15px 15px 0;
}
.alignright {
	float: right;
	width: auto;
	margin: 5px 0 15px 15px;
}
.aligncenter {
	text-align: center;
	margin-bottom: 5px;
}
img.aligncenter {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

pre { }
code, tt { }

.meta { }
.postmetadata { }

/* SHARING */

.sharing h4 {
	margin-bottom: 0;
	padding-bottom: 0;
}
.sharing ul {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 5px;
	padding-bottom: 0;
}
.sharing li {
	float: left;
}
.sharing li:first-child {
	padding-left: 0;
}
.sharing .share-twitter {
	padding-top: 1px;
}
.sharing .share-facebook {
	padding-top: 1px;
}
.sharing .share-googleplus {
	margin-left: 30px;
}

/* POST NAVIGATION */

