@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?khw85k');
  src:  url('../fonts/icomoon.eot?khw85k#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?khw85k') format('truetype'),
    url('../fonts/icomoon.woff?khw85k') format('woff'),
    url('../fonts/icomoon.svg?khw85k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user3:before {
  content: "\e90e";
}
.icon-info:before {
  content: "\e910";
}
.icon-copy:before {
  content: "\e90d";
}
.icon-users:before {
  content: "\e905";
}
.icon-user-plus:before {
  content: "\e906";
}
.icon-calendar-plus-o:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-login:before {
  content: "\e90f";
}
.icon-log-out:before {
  content: "\e90a";
}
.icon-users2:before {
  content: "\e907";
}
.icon-user2:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e114";
}
.icon-circle-cross:before {
  content: "\e909";
}
.icon-menu:before {
  content: "\e904";
}
.icon-user:before {
  content: "\e902";
}
.icon-pen:before {
  content: "\e903";
}
.icon-location:before {
  content: "\e901";
}
.icon-note:before {
  content: "\e900";
}




// Structure
$pageWidth: 1000px;

// Text Selection
$selectionColor: orange;

// Links
$linkColor: blue;
$linkHoverColor: blue;

// Other
